@import "@scss/components/header.scss";
@import "@scss/components/normalize.scss";
@import "@scss/components/forMenuActive.scss";

$hiddenInputPlaceholderColor: transparent;

.custom-color {
  background: #F8FBFC;
}

.login-logo {
  padding: 174px 40px 174px 40px;
  .login-logo__img {
    width: 880px;
    height: 512px;
  }
}

.login-main-text {
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 100%;
  color: #395B64;
  margin-bottom: 40px;
}

.login-field {
  margin-bottom: 40px;
  .login-field_item-text, .form-check-label {
    margin-bottom: 20px;
    font-weight: 200;
    font-size: 16px;
    line-height: 18px;
    color: #395B64;
    text-decoration: none;
  }
  .input {
    border-bottom: 1px solid #395B64;
  }
  .login-field__btn {
    width: 65px;
    height: 40px;
    background: #395B64;
    border-radius: 10px;
    color: #C0D9E0;
  }
}

input {
  width: 100%;
  height: 38px;
  padding: 11px;
  border: 0;
}

input::placeholder {
  color: #C0D9E0;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
}

input:focus::-webkit-input-placeholder {
  color: $hiddenInputPlaceholderColor;
}

input:focus::-moz-placeholder {
  color: $hiddenInputPlaceholderColor;
}

input:focus:-ms-input-placeholder {
  color: $hiddenInputPlaceholderColor;
}

.input:focus +.form-check-label{
  font-weight: bold;
}

@media (max-width: 576px) {
  .login-field__small-display {
    width: 150%;
  }
}






